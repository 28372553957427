import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TimerComponent } from './timer/timer.component'
import { OverviewComponent } from './overview/overview.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AuthGuard } from './auth.guard';
import { DaysComponent } from './days/days.component';
import { LetterComponent } from './letter/letter.component';
import { RedirectComponent } from './redirect/redirect.component';


const routes: Routes = [
  {
    path: '',
    component: RedirectComponent,
  },
  {
    path: 'kirstine',
    component: LetterComponent,
  },
  {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sign-in',
    component: UserProfileComponent,
  }
  ,
  {
    path: 'dager',
    component: DaysComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
