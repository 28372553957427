import { Component } from '@angular/core';
import { Timer } from "easytimer.js";
import { AngularFirestoreDocument, AngularFirestore,  } from '@angular/fire/firestore';
import { TimeEntry } from '../time-entry';
import { AuthService } from '../auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import '@firebase/firestore';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent {
  timer: Timer;
  timeEntry: TimeEntry;
  
  constructor(private authsSrvice: AuthService, private afAuth: AngularFireAuth){
    this.timeEntry = new TimeEntry();
    var user = this.afAuth.auth.currentUser;
    this.timeEntry.userId = 'test';
    this.timeEntry.label = 'workhobby';
    if(user){
      this.timeEntry.userId = user.uid;
    }
    this.timer = new Timer();
  }
  onStart(){
    if (!this.timer.isRunning()) this.timer.start();
    this.timeEntry.startTime = new Date();
  }
  onPause(){
    if (!this.timer.isPaused()) this.timer.pause(); 
  }
  onStop(){
    this.timer.stop();
    this.timeEntry.endTime = new Date();
    this.saveTimerToFirestore();
  }
  private saveTimerToFirestore() {
    this.authsSrvice.saveTimerToFirestore(this.timeEntry);
  }
}
