import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.css']
})
export class DaysComponent implements OnInit {
  days = "";
  daysAgo = ""

  constructor() { }

  ngOnInit() {
    var eventdate = moment("2021-12-17");
    var startDate = moment("2021-08-14");
    var todaysdate = moment();
    this.days = eventdate.diff(todaysdate, 'days').toString();
    this.daysAgo = startDate.diff(todaysdate, 'days').toString();
  }

}
