import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './user'; // optional

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TimeEntry } from './time-entry';

@Injectable({ providedIn: 'root' })
export class AuthService {

    user$: Observable<User>;
    

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) {
        // Get the auth state, then fetch the Firestore user document or return null
        this.user$ = this.afAuth.authState.pipe(
            switchMap(user => {
                // Logged in
                if (user) {
                    return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
                } else {
                    // Logged out
                    return of(null);
                }
            })
        )
    }

    async googleSignin() {
        const provider = new auth.GoogleAuthProvider();
        const credential = await this.afAuth.auth.signInWithPopup(provider);
        return this.updateUserData(credential.user);
    }

    private updateUserData(user: User) {
        // Sets user data to firestore on login
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

        const data = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL
        };

        return userRef.set(data, { merge: true });
    }

    async signOut() {
        await this.afAuth.auth.signOut();
        this.router.navigate(['/']);
    }

    saveTimerToFirestore(timeEntry: TimeEntry) {
        const timeEntryRef = this.afs.collection(`timeEntries`);
        console.dir(timeEntry);
        
        const data: TimeEntry = {
            userId: timeEntry.userId,
            startTime: timeEntry.startTime,
            endTime: timeEntry.endTime,
            label: timeEntry.label,
        };
        return timeEntryRef.add(data).then(ref => {console.log('Added document with ID: ', ref.id);
        })
    }
}
