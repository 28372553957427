interface ITimeEntry {
    userId: string,
    startTime: Date,
    endTime?: Date,
    label?: string,
}
export class TimeEntry implements ITimeEntry{
    userId: string;
    startTime: Date;
    endTime?: Date;
    label?: string;
}