import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import {MatNativeDateModule,MatCheckboxModule,MatFormFieldModule,MatInputModule,MatRadioModule,MatListModule} from  '@angular/material';

import {MatDatepickerModule} from  '@angular/material/datepicker';


@NgModule({
  declarations: [],
  imports: [
    MatButtonModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,MatCheckboxModule,MatFormFieldModule,MatInputModule,MatRadioModule,MatListModule,MatDatepickerModule
  ],
  exports:[
    MatButtonModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,MatCheckboxModule,MatFormFieldModule,MatInputModule,MatRadioModule,MatListModule,MatDatepickerModule
  ]
})
export class MaterialModule { }
