import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';


@Component({
  selector: 'app-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.css']
})
export class LetterComponent implements OnInit {
  password = '';
  passwordForm: FormGroup;
  showMessage= false

  ngOnInit(): void {
    this.passwordForm = new FormGroup({
      password: new FormControl(this.password, [
        Validators.required,
        Validators.minLength(4)
      ]),
  
  })};
  submit(formDir: FormGroupDirective): void{
    console.log(formDir);
    
    if(formDir.control.value.password === "the way"){
      console.log("correct");
      this.showMessage = true;
      
    }
  }
  get pass() { return this.password }
}
