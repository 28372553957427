import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TimerComponent } from './timer/timer.component';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './header/header.component';
import { OverviewComponent } from './overview/overview.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { environment } from '../environments/environment';
import { AuthGuard } from './auth.guard';
import { DaysComponent } from './days/days.component';
import { LetterComponent } from './letter/letter.component';
import { RedirectComponent } from './redirect/redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    TimerComponent,
    HeaderComponent,
    OverviewComponent,
    UserProfileComponent,
    DaysComponent,
    LetterComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    MaterialModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent] // rootModule
})
export class AppModule { }
